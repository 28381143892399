<template>
  <table class="table table-bordered">
    <thead class="thead-dark">
      <tr>
        <th class="text-center">#</th>
        <th>Name</th>
        <th class="text-center">Typ</th>
        <th class="text-center">Anzeigen</th>
        <th class="text-center">Aktive Studios</th>
        <th class="text-center">Bearbeiten</th>
        <th class="text-center">Löschen</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td colspan="8">
          <router-link :to="{name: 'promotion-create'}">+ Promotion erstellen</router-link>
        </td>
      </tr>
      <tr v-for="(promotion, index) in promotions" :key="promotion.id">
        <th class="text-center">{{ index + 1}}</th>
        <td>{{ promotion.name }}</td>
        <td class="text-center">
          <i class="fa fa-fw fa-video-camera" v-if="promotion.type.includes('video')"></i>
          <i class="fa fa-fw fa-image" v-else></i>
        </td>
        <td class="text-center">
          <a :href="promotion.src" target="_blank">Anzeigen</a>
        </td>
        <td class="text-center">{{promotion.studios.length}}</td>
        <td class="text-center">
          <router-link :to="{name: 'promotion-details', params: {id: promotion.id}}">
            <i class="fa fa-edit text-dark"></i>
          </router-link>
        </td>
        <td class="text-center" @click="remove(promotion.id)">
          <i class="fa fa-trash cursor-pointer"></i>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { firestore } from '../firebase';

export default {
  data() {
    return {
      promotions: [],
      loading: false
    };
  },
  async created() {
    this.loading = true;

    const promotionsSnapshot = await firestore
      .collection('promotions')
      .orderBy('name')
      .get();

    this.promotions = promotionsSnapshot.docs.map(snapshot => {
      return {
        id: snapshot.id,
        ...snapshot.data()
      };
    });

    this.loading = false;
  },
  methods: {
    async remove(id) {
      if (
        confirm('Bist du sicher, dass du diese Promotion löschen möchtest?')
      ) {
        await firestore
          .collection('promotions')
          .doc(id)
          .delete();

        this.promotions = this.promotions.filter(
          promotion => promotion.id !== id
        );
      }
    }
  }
};
</script>
